import App from './App.vue'
import router from '@/plugins/router'
import store from './store'
import instanceVuetify from '@/plugins/vuetify'
import './watchDog'
import { globalConfig } from './config/Apps'


export {router}
export async function plugins(plugins){
  const vuetify = await instanceVuetify()
  return {
    ...plugins,
    // apolloProvider,
    router,
    store,
    vuetify,
  }
}

export default {
  name: 'Bootstrap',
  render(h) {
    return h(App)
  },
}