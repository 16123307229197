
export function trim(val) {
    if (typeof val !== 'string') return ''
    return val.replace(/^\s*|\s*$/g, '')
}

// 检测判断非空
export function isEmpty(val) {
    return null === val || trim(val) === ''
}

