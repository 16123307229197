import Vue from 'vue'
import Vuetify from 'vuetify'
import { apps } from '@/config/Apps'

Vue.use(Vuetify)

export default async function instanceVuetify() {
  let i18n = {}
  const i18s = await Promise.all(Object.values(apps).map(app=>app.i18n()))
  for(let i18 of i18s ){
    if(i18){
      const langs = Object.keys(i18);
      for(let langeName of langs){
        if(i18n.hasOwnProperty(langeName)){
          Object.assign(i18n[langeName], i18[langeName])
        }else{
          i18n[langeName] = {...i18[langeName]}
        }
      }
    }
  }
  // console.log(i18n, app, 1212)

  const allLangs = Object.keys(i18n)
  const currentLang = allLangs.includes('en') ? 'en' : (allLangs[0] ? allLangs[0] : 'en')
  const opts = {
    breakpoint: {
      thresholds: {
        xs: 600,
        sm: 960,
        md: 1280,
        lg: 1920,
      },
      mobileBreakpoint: 'sm' // 这个值等于960
    },
    icons: {
      iconfont: 'mdi', // 默认值 - 仅用于展示目的
    },
    lang: {
      locales: i18n,
      current: currentLang,
    },
  }
  return new Vuetify(opts)
}
