import appConfig from './Config'

const library = function slibraryBootstrap() {
  return import('library/Bootstrap')
}

async function mergeGlobalConfig(types) {
  const gs = await Promise.all(appConfig.map(cfg=>cfg.entry()))
  const config = {}
  for(let type of types){
    const typeCfg = {}
    for(let g of gs){
      Object.assign(typeCfg, g[type])
    }
    config[type] = typeCfg
  }
  return config
}

const globalConfig = mergeGlobalConfig(['uses', 'globals','plugins','filters'])

const __http = async () => {
  const module = await library()
  return module.http
}

const apps = {}

for (let app of appConfig) {
  apps[app.name] = {
    name: app.name,
    label: app.label,
    show: app.show,
    icon: app.icon,
    routes: async () => {
      const module = await app.entry()
      return module.routes
    },
    store: async () => {
      const module = await app.entry()
      return module.store
    },
    httpConfig: async (instance) => {
      const module = await app.entry()
      return module.httpConfig(instance)
    },
    i18n: async() => {
      const module = await app.entry()
      return module.i18n
    }
  }
}
console.log(apps, 57)
export { apps,
  __http ,
  library ,
  globalConfig
}


