import Vue from 'vue'
import Bootstrap, { plugins,router } from './Bootstrap'
import { globalConfig } from './config/Apps'

// 定义全局变量
// for(let g in global) {
//   Vue.prototype[g] = global[g]
// }

async function initApp() {
  const cfg = await globalConfig
  for (let key in cfg.globals) {
    if(key === '$loading') {
      cfg.globals[key](Vue)
    } else {
      Vue.prototype[key] = cfg.globals[key]
      if(key === '$http') {
        Vue.prototype[key].prototype.$router = router
      }
    }
  }
  for (let key in cfg.uses) {
    Vue.use(cfg.uses[key])
  }

  for (let key in cfg.filters) {
    Vue.filter(key, cfg.filters[key])
  }
  Vue.prototype.$projectName="Quick Order"
  const app = new Vue({
    ...await plugins(cfg.plugins),
    render: h => h(Bootstrap)
  })
  app.$mount('#app')
  // window.__app = app
}

initApp(Vue)

// globals().then(g => {
//   for (let key in g) {
//     Vue.prototype[key] = g[key]
//   }
// })
// uses().then(g => {
//   for (let key in g) {
//     Vue.use(g[key])
//   }
// })

// plugins().then(plugin => {
//   const app = new Vue({
//     ...plugin,
//     render: h => h(Bootstrap)
//   })
//   app.$mount('#app')
//   window.app = app
// })
// 本地引入mock
// if ( _ENV_.NODE_ENV === 'local') {
//   require(/* webpackIgnore */'./mock/index')
// }

