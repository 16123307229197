/**
 * Author Grady
 * 说明：本文件主要进行路由及状态的加载，根据模块的配置来动态加载
 * email: shixiang@skystartrade.com
 * last updated date: 6.25.2021
 */
import router from "@/plugins/router";
import store from "./store";
import { apps, __http } from "./config/Apps";
import configApps from "./config/Config";
import Vue from "vue";
const __httpInstance = __http;
// __httpInstance.prototype.$router = router
// 获取当前页面对应的子应用模块名称,默认为第一个
// function getModuleName(path) {
//   let keys = Object.keys(apps);
//   const permissonApps = store.getters._permissionApps;

//   keys = permissonApps.filter(app => new Set(keys).has(app.name) ? true : false)
//   const appName = keys[0] && keys[0].name || ''
//   return isIndexUrl(path) ? appName :
//     path.startsWith('/') ? path.split('/')[1] : path;
// }

function getModuleName(path) {
  const appNameFromPath = path.startsWith("/") ? path.split("/")[1] : path;
  if(appNameFromPath){
    return appNameFromPath != 'library' && apps.hasOwnProperty(appNameFromPath)?appNameFromPath: configApps[0].name
  }else {
    const permissonApps = store.getters._permissionApps;
    const appName = permissonApps.find(app=>apps.hasOwnProperty(app))
    return appName || ''
  }
}
function isIndexUrl(path) {
  return path === "/" ? true : false;
}

function getCurrentStore(moduleName) {
  return apps[moduleName] && apps[moduleName].store;
}

// 预加载路由，需要在第一次加载时加载多个模块的路由在同一个路由中，以便在第一页的菜单列表中包含多模块的路由
async function registerRouteAndStore(currModuleName, router, store) {
  if (!apps[currModuleName]) {
    throw new Error("No app can access or config");
  }
  let resource = [ __httpInstance()];
  const modules = []
  for(let module of configApps){
    const moduleName = module.name
    if (store.getters._permissionRoutes.hasOwnProperty(moduleName)) continue;
    if(module.preload || moduleName === currModuleName){
      modules.push(moduleName)
      resource.push(apps[moduleName].routes())
    }
  }
  const needLoadStore = !store.state._permission.registeredStores.hasOwnProperty(currModuleName)
  if(needLoadStore){
    resource.push(getCurrentStore(currModuleName)())
  }
  resource = await Promise.all(resource)
  resource[0] = apps[currModuleName].httpConfig(resource[0])
  const storeModules = needLoadStore && resource.pop().modules
  for(let i = 1, len = resource.length; i < len; i++){
    resource[i] = resource[i].asyncRoutes()
  }
  resource = await Promise.all(resource)
  Vue.prototype.$http = resource.shift();
  if (resource.length > 0) {
    let allRoutes = []
    for (let i = 0, len = modules.length; i < len; i++) {
      store.dispatch("_permission/addRouters", {
        moduleRoutes: resource[i].routes,
        moduleName: modules[i],
      });
      allRoutes.push(...resource[i].routes)
    }
    router.addRoutes(allRoutes);
  }
  store.dispatch("_permission/changeModuleRoutes", {moduleName: currModuleName });
  if(needLoadStore){
    store.dispatch("_permission/registerStore", {
      moduleName: currModuleName,
      modules: storeModules
    });
  }
  dynamicRegisterStore(currModuleName, store)
}

// 初始化store，根据应用来动态切换Store
async function dynamicRegisterStore(newModuleName, store, oldModuleName) {
  const pendingRegisterModules =
      store.state._permission.registeredStores[newModuleName];
  for (let key in pendingRegisterModules) {
    const module = pendingRegisterModules[key];
    !store.hasModule(key) && store.registerModule(key, module);
  }
  // debugger
}

// 获取并注册所有子应用
async function registerApps(moduleName, path) {
  if (
      store.state._permission.apps.some(app=>app.name === moduleName)
  ) {
    const allApps = store.state._permission.apps.map((app) => app.name);
    return allApps;
  }
  // 第二期再开通动态加载应用的逻辑
  // let $http = await http();
  // 登录授权步骤TODO
  // let { error, data } = await $http.get(getApps, null, { loading: false })
  // 返回数据示例
  // const data = [
  //   {
  //     name: 'productCenter',
  //     path: '/productcenter'
  //   },
  //   // {
  //   //   name: 'app2',
  //   //   path: '/app2'
  //   // },
  //   {
  //     name: 'lib',
  //     path: '/lib'
  //   },
  // ]
  // const permissionApps = data.filter(app => apps[app.name] ? true : false)
  // store.dispatch('_permission/addApps', { modules: permissionApps })

  // 需要接口支持返回登录字段 TODO， path可以通过全局环境变量去拿，配置在全局config中
  // 根据实际情况添加判断，下面仅是示例
  // if (!data) {
  //   router.push('login');
  //   return;
  // }

  // 不需要授权的应用，直接把配置中的拿 过来即可
  const permissionApps = Object.keys(apps).map(appName => { return { name: appName } })
  // 注册有权限的子应用
  store.dispatch("_permission/addApps", { modules: permissionApps });

  return permissionApps.map((item) => item.name);
}

// 开始切换应用时的加载状态
function startAppChangeLoading() {
  store.dispatch("_permission/setChangeAppLoading", { loading: true });
}
// 结束切换应用时的加载状态
function endAppChangeLoading() {
  store.dispatch("_permission/setChangeAppLoading", { loading: false });
}

// 重新加载整个应用时加载状态
function startLoading() {
  store.dispatch("_permission/setAppLoading", { loading: true });
}
// 结束整个应用加载状态
function endLoading() {
  store.dispatch("_permission/setAppLoading", { loading: false });
}
startLoading()
const loginUrl = _ENV_.SOURCE.LOGIN_URL
const enviroment = _ENV_.SOURCE.NODE_ENV
router.beforeEach(async (to, from, next) => {
  const toPath = to.fullPath.toLowerCase()
  // 如果本地没有授权，则跳转去登录
  if (!localStorage.getItem('auth-token')) {

    if (!toPath.startsWith('/login')) {
      next('/login?returnUrl=' + toPath)
    } else {
      endLoading()
      next(true)
    }
  } else {
    const userInfo = JSON.parse(localStorage.getItem('auth-token')).user_info
    let moduleName = getModuleName(toPath)
    store.dispatch('_app/setUserInfo', userInfo)
    // access permission Apps, if no login will redirect login page
    if (store.getters._currentApp !== null && moduleName === store.getters._currentApp.toLowerCase()) {
      if (to.matched.length > 0) {
        next()
      } else {
        router.replace(store.getters._currentRoutes[0].path)
      }
    } else {
      if (!store.getters._currentApp || store.getters._currentApp.toLowerCase !== moduleName) {

        // 切换应用加载一定是整个主应用加载完成后才启用,切换应用状态
        // if (!store.getters._changeAppLoading && toPath === '/' + moduleName.toLowerCase()) {
        startAppChangeLoading();
        // }
        const registeredApps = await registerApps(moduleName, location.href);
        // if no apps allowed access , show some messages
        if (registeredApps.length === 0) {
          console.warn("no apps allowed access");
          return next(false);
        }
        if (!registeredApps.includes(moduleName)) {
          moduleName = registeredApps[0];
        }
        // 注册需要预加载的路由

        await registerRouteAndStore(moduleName, router, store);

        store.getters._isAppLoading && endLoading();
        endAppChangeLoading();
        if (toPath.toLowerCase().indexOf(moduleName) === -1) {
          router.replace(store.getters._currentRoutes[0].path);
        } else {
          next({path: toPath})
        }
      } else {
        if (to.matched.length > 0) {
          next();
        } else {
          next({path: store.getters._currentRoutes[0].path})
        }
      }
    }
  }
});
// 当路由发生错误时重新刷新页面
router.onError((error, to, from) => {
  location.reload();
});
